@tailwind base;
@tailwind components;
@tailwind utilities;

/* custom google font "Lato"*/
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");

@layer base {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Lato", sans-serif;
  }

  body {
    @apply bg-slate-50 overflow-x-hidden;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #f1f5f9;
  }

  tr:nth-child(even):hover {
    background-color: #cbd5e1;
  }

  tr:nth-child(odd) {
    background-color: #fff;
  }

  tr:nth-child(odd):hover {
    background-color: #cbd5e1;
  }
}
